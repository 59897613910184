const data = [
  {
    num: "1",
    mintColor: "green",
    text: "Connect Your Wallet",
  },
  {
    num: "2",
    mintColor: "blue",
    text: "Select Your Quantity",
  },
  {
    num: "3",
    mintColor: "yellow",
    text: "Confiem the Transaction",
  },
  {
    num: "4",
    mintColor: "red",
    text: "Receive Your NFTs",
  },
];

export default data;
