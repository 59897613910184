import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.svg";
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg";
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.svg";

const data = {
  aboutDescription1:
    "Bitcoin Ordinals protocol allows individuals to utilize satoshis as a unit of Bitcoin. It assigns a unique identifier and is transacted with extra data attached. Ordinals is a mechanism of trading smaller units of Bitcoin through the BRC20 chain placed on top of the original Bitcoin blockchain. Ordinals theory drives the mechanics behind how Bitcoin ordinals work. RATS is a BRC20 based memecoin on the Bitcoin blockchain via Ordinals.",
  aboutDescription2:
    "RATS is a BRC20 based memecoin on the Bitcoin blockchain via Ordinals.",
  aboutInfo: [
    {
      icon: aboutCardIcon1,
      title: "Consensus NFTs",
      text: "The Rats NFT contains the Conflux Confi Mascot, and each NFT contains a certain number of BRC20 RATS, and the NFT can be redeemed for Rats at any time.",
    },
    {
      icon: aboutCardIcon2,
      title: "Staking to Earn",
      text: "Your NFTs can be stored in a contract to mine Rats tokens CRC20, the more Crazy-Rats NFT you have, the more  you will be rewarded, even if the BRC20 Rats are withdrawn.",
    },
    {
      icon: aboutCardIcon3,
      title: "Cross Chain Value",
      text: "When you buy the Crazy-Rats NFT, you're actually buying BRC20-rats, and also Conflux digital gold FC, and you'll be handsomely rewarded no matter which token grows!",
    },
  ],
};

export default data;
